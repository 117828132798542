import { useContext } from 'react';
import { Box, CircularProgress, Divider, Link, Typography, useTheme } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import { ferieTabStateAtom } from '../../../../contextsAndAtoms/FerieAtoms';
import { FerieInfoText } from '../FerieInfoText';
import { FerieContext } from '../FerieTab';
import { Year } from '../Year';
import { FerieStatusDataRow } from './FerieStatusDataRow';
import { FerieStatusHeader } from './FerieStatusHeader';

import { drawerStateAtom } from '~src/contextsAndAtoms/DrawerAtoms';

export const FerieStatusTab = () => {
    const theme = useTheme();
    const { employee, me } = useAtomValue(drawerStateAtom);
    const p = usePhrases('meny');
    const { isInitialLoading } = useAtomValue(ferieTabStateAtom);
    const { ferieInfo } = useContext(FerieContext);
    return (
        <Box display="flex" flexDirection="column" height={'100%'} margin={'15px'} mt={'10px'}>
            <Year />
            <Divider sx={{ width: '50%', mb: '10px', placeSelf: 'center' }} />
            <Box
                display={'grid'}
                gridTemplateColumns={'110px repeat(3, 1fr)'}
                gridTemplateAreas={'"dato onsk plan reg"'}
                sx={{ gap: '8px' }}
            >
                <FerieStatusHeader />
                {isInitialLoading ? (
                    <Box
                        gridColumn={'1 / -1'}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        width={'100%'}
                    >
                        <CircularProgress />
                    </Box>
                ) : ferieInfo?.length && ferieInfo?.length > 0 ? (
                    <Box mb={'80px'} sx={{ gridColumn: '1/-1' }}>
                        {ferieInfo?.map((entry) => {
                            return (
                                <Box
                                    key={entry.Uke}
                                    gridColumn={'1 / -1'}
                                    width={'100%'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    flexDirection={'column'}
                                >
                                    <Typography
                                        mt={'5px'}
                                        fontSize={14}
                                        fontWeight={800}
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            window.open(
                                                `https://${location.hostname}/plan?visning=uke&dato=${format(
                                                    new Date(entry.FerieEntries[0].Date),
                                                    'yyyy-MM-dd'
                                                )}&ferie=true&ansatt=${employee?.Id}&avdeling=${employee?.AvdelingId}`,
                                                '_self'
                                            );
                                        }}
                                    >
                                        {p('week')} {entry.Uke === -1 ? 1 : entry.Uke}
                                    </Typography>
                                    <Divider sx={{ width: '100%', margin: '10px 0' }} />

                                    {entry.FerieEntries.map((ferie) => {
                                        return <FerieStatusDataRow key={ferie.Date} ferie={ferie} />;
                                    })}
                                </Box>
                            );
                        })}
                    </Box>
                ) : (
                    <Box
                        gridColumn={'1 / -1'}
                        width={'100%'}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        <Typography gridColumn={'1 / -1'} fontStyle={'italic'} fontSize={14} textAlign={'center'}>
                            {p('no_vacation_requests')}
                        </Typography>
                        {employee?.Id === me?.Id && (
                            <Link
                                gridColumn={'1 / -1'}
                                textAlign={'center'}
                                href={`https://${location.hostname}/plan?visning=uke&dato=${format(
                                    Date.now(),
                                    'yyyy-MM-dd'
                                )}&ferie=true&ansatt=${employee?.Id}&avdeling=${employee?.AvdelingId}`}
                            >
                                {p('go_to_plan_add_vacation')}
                            </Link>
                        )}
                    </Box>
                )}
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                    sx={{
                        position: 'absolute',
                        bottom: '60px',
                        paddingBottom: '10px',
                        left: '0',
                        right: '0',
                        padding: '10px',
                        borderTop: `2px solid ${theme.palette.divider}`,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    <FerieInfoText />
                </Box>
            </Box>
        </Box>
    );
};
