import { useEffect } from 'react';

export const EventOnMount = () => {
    useEffect(() => {
        const event = new CustomEvent('employeePanelOpened');
        window.dispatchEvent(event);

        return () => {
            const unmountEvent = new CustomEvent('employeePanelClosed');
            window.dispatchEvent(unmountEvent);
        };
    }, []);
    return <></>;
};
