import { useCallback, useEffect, useRef, useState } from 'react';
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { Box, CircularProgress, debounce, Dialog, useMediaQuery, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { EmployeeDrawerConfig } from '$helpers/types';
import useIsMobile from '$hooks/useIsMobile';
import TbButton from './muiBien/TbButton';
import { FerieTab, FerieTabEnum } from './tabs/ferie/FerieTab';
import { InfoTab } from './tabs/info/InfoTab';
import { LogoutTab } from './tabs/logout/logout';
import { PlanTab } from './tabs/plan/PlanTab';
import TopBar from './topBar/TopBar';
import { EventOnMount } from './utility/EventOnMount';
import TabSelector from './TabSelector';

import {
    databaseId,
    employeeAtom,
    employeeIdAtom,
    fullViewAtom,
    hasAdminOrAvdLederAtom,
    hasFerieAccessAtom,
    isOpenAtom,
    meAtom,
    openTabAtom,
    TabsEnum,
} from '~src/contextsAndAtoms/DrawerAtoms';
import { ferieTabStateAtom, yearAtom } from '~src/contextsAndAtoms/FerieAtoms';
import SignalREventHubContext from '~src/contextsAndAtoms/SignalREventHubContext';

const EmployeeDrawer = () => {
    const [allowBackdropScroll, setAllowBackdropScroll] = useState(true);
    const employeePanelRef = useRef<HTMLDivElement>(null);
    const isMobile = useIsMobile();
    const theme = useTheme();
    const scrollRef = useRef<HTMLDivElement>(null);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const isLargeScreen = useMediaQuery('(min-width:768px)');
    const [topOverride, setTopOverride] = useState<number | null>(isLargeScreen ? 60 : 50);
    const [zIndexOverride, setZIndexOverride] = useState<number | null>(null);
    const [shouldTransition, setShouldTransition] = useState(true);

    const [isOpen, setIsOpen] = useAtom(isOpenAtom);
    const [employeeId, setEmployeeId] = useAtom(employeeIdAtom);
    const fullView = useAtomValue(fullViewAtom);
    const [openTab, setOpenTab] = useAtom(openTabAtom);
    const [openSubTab, setOpenSubTab] = useState(FerieTabEnum.Status);
    const [hasAdminOrAvdLederAccess, setHasAdminOrAvdLederAccess] = useAtom(hasAdminOrAvdLederAtom);
    const [hasFerieAccess, setHasFerieAccess] = useAtom(hasFerieAccessAtom);
    const { data: employee, isPending: employeePending } = useAtomValue(employeeAtom);
    const { data: me, isPending: mePending } = useAtomValue(meAtom);
    const setDatabaseId = useSetAtom(databaseId);
    const [year, setYear] = useAtom(yearAtom);
    const setFromAndTo = useSetAtom(ferieTabStateAtom);
    const [config, setConfig] = useState<EmployeeDrawerConfig | null>(null);

    const open = (employeeId: number, config?: EmployeeDrawerConfig) => {
        employeeId = parseInt(employeeId.toString(), 10);
        const annoyingChatIcon = document.getElementById('hubspot-messages-iframe-container');
        annoyingChatIcon?.style.setProperty('display', 'none', 'important');
        const annoyingIcon2 = document.getElementById('_hj_feedback_container');
        annoyingIcon2?.style.setProperty('display', 'none', 'important');
        const year = config?.year ?? new Date().getFullYear();
        setEmployeeId(employeeId);
        setIsOpen(true);
        setYear(year);
        setFromAndTo({ from: new Date(year, 0, 1), to: new Date(year, 11, 31) });
        setConfig(config ?? null);
        setZIndexOverride(config?.zIndex ?? null);
        setTopOverride(config?.top ?? (isLargeScreen ? 60 : 50));
        setTimeout(() => {
            setShouldTransition(false);
        }, 0);
    };

    useEffect(() => {
        setFromAndTo({ from: new Date(year, 0, 1), to: new Date(year, 11, 31) });
    }, [year, setFromAndTo]);

    const close = () => {
        const annoyingChatIcon = document.getElementById('hubspot-messages-iframe-container');
        annoyingChatIcon?.style.setProperty('display', 'block', 'important');
        const annoyingIcon2 = document.getElementById('_hj_feedback_container');
        annoyingIcon2?.style.setProperty('display', 'block', 'important');
        setShouldTransition(true);
        setIsOpen(false);
    };

    window.employeePanel = {
        employeeId: employeeId,
        open: open,
        close: close,
        isOpen: isOpen,
    };

    useEffect(() => {
        const handleScroll = debounce(() => {
            if (scrollRef.current) {
                const isVisible = scrollRef.current.scrollTop > 150;
                setShowScrollButton(isVisible);
            }
        }, 40);

        const scrollableElement = scrollRef.current;
        scrollableElement?.addEventListener('scroll', handleScroll);

        return () => {
            scrollableElement?.removeEventListener('scroll', handleScroll);
        };
    }, [openTab]);

    useEffect(() => {
        if (!me) return;
        setDatabaseId(me.PostId);
        setHasAdminOrAvdLederAccess(
            !!(
                me &&
                (me.ErAdmin ||
                    (me.ErAvdelingsleder &&
                        employee &&
                        employee.AvdelingId &&
                        me.LederFor &&
                        me.LederFor.includes(employee.AvdelingId)))
            )
        );

        setHasFerieAccess(
            !!(
                me?.Id === employeeId ||
                (me &&
                    (me.ErAdmin ||
                        (me.ErAvdelingsleder &&
                            employee &&
                            employee.AvdelingId &&
                            me.LederFor &&
                            me.LederFor.includes(employee.AvdelingId))))
            )
        );
    }),
        [me, employeeId, employee];

    const hasAccessCurrentTab = useCallback(() => {
        switch (openTab) {
            case TabsEnum.Ferie:
                if (openSubTab === FerieTabEnum.Status) {
                    return hasFerieAccess;
                }
                return hasAdminOrAvdLederAccess;
            case TabsEnum.Logout:
                return me?.Id === employee?.Id;
            default:
                return true;
        }
    }, [employee?.Id, hasAdminOrAvdLederAccess, hasFerieAccess, me?.Id, openSubTab, openTab]);

    useEffect(() => {
        if (!hasAccessCurrentTab()) {
            setOpenTab(TabsEnum.Status);
        }
    }, [hasAccessCurrentTab, hasAdminOrAvdLederAccess, hasFerieAccess, me, employee, openTab, openSubTab, setOpenTab]);

    useEffect(() => {
        if (config?.openPanels) {
            if (config.openPanels[0] === 'arbeidsplan') {
                setOpenTab(TabsEnum.Plan);
            } else if (config.openPanels[0] === 'ferie' || config.openPanels[0] === 'feriebehandling') {
                hasFerieAccess ? setOpenTab(TabsEnum.Ferie) : setOpenTab(TabsEnum.Status);
                if (config.openPanels[0] === 'feriebehandling' && hasAdminOrAvdLederAccess) {
                    setOpenSubTab(FerieTabEnum.Onsker);
                } else {
                    setOpenSubTab(FerieTabEnum.Status);
                }
            } else {
                setOpenTab(TabsEnum.Status);
            }
        }
    }, [config, hasFerieAccess, hasAdminOrAvdLederAccess, me, setOpenTab]);

    useEffect(() => {
        const preventScroll = (e: WheelEvent | TouchEvent) => {
            const isInEmployeePanel = employeePanelRef.current?.contains(e.target as Node);
            const isInScrollRef = scrollRef.current?.contains(e.target as Node);

            if (isInEmployeePanel) {
                e.stopPropagation();
                if (!allowBackdropScroll) {
                    const scrollTop = scrollRef.current?.scrollTop ?? 0;
                    const scrollHeight = scrollRef.current?.scrollHeight ?? 0;
                    const clientHeight = scrollRef.current?.clientHeight ?? 0;
                    const isScrollable = scrollHeight > clientHeight;

                    if (!isInScrollRef || !isScrollable) {
                        e.preventDefault();
                    } else if (isInScrollRef) {
                        if (e instanceof WheelEvent) {
                            if (
                                (scrollTop === 0 && e.deltaY < 0) ||
                                (scrollTop + clientHeight >= scrollHeight && e.deltaY > 0)
                            ) {
                                e.preventDefault();
                            }
                        } else if (e instanceof TouchEvent) {
                            const touch = e.touches[0];
                            const touchStartY = touch.clientY;

                            const touchMoveHandler = (moveEvent: TouchEvent) => {
                                const touchMoveY = moveEvent.touches[0].clientY;
                                const touchDeltaY = touchStartY - touchMoveY;

                                if (
                                    (scrollTop === 0 && touchDeltaY < 0) ||
                                    (scrollTop + clientHeight >= scrollHeight && touchDeltaY > 0)
                                ) {
                                    moveEvent.preventDefault();
                                }
                            };

                            document.addEventListener('touchmove', touchMoveHandler, { passive: false });

                            const touchEndHandler = () => {
                                document.removeEventListener('touchmove', touchMoveHandler);
                                document.removeEventListener('touchend', touchEndHandler);
                                document.removeEventListener('touchcancel', touchEndHandler);
                            };

                            document.addEventListener('touchend', touchEndHandler);
                            document.addEventListener('touchcancel', touchEndHandler);
                        }
                    }
                }
            }
        };

        if (!isMobile) {
            if (isOpen) {
                document.addEventListener('wheel', preventScroll, { passive: false });
                document.addEventListener('touchstart', preventScroll, { passive: false });
            } else {
                document.removeEventListener('wheel', preventScroll);
                document.removeEventListener('touchstart', preventScroll);
            }
        }

        return () => {
            document.removeEventListener('wheel', preventScroll);
            document.removeEventListener('touchstart', preventScroll);
        };
    }, [allowBackdropScroll, isOpen, isMobile]);

    window.onkeydown = (e: KeyboardEvent) => {
        const n = Object.values(TabsEnum).length;
        const tabAccess = [true, true, hasFerieAccess, employeeId === me?.Id && !me?.LoggetInnMedUserKey];

        if (e.key === 'Escape') {
            close();
        } else if (e.key === 'ArrowRight' && isOpen) {
            let nextTab = (openTab + 1) % n;
            while (!tabAccess[nextTab]) {
                nextTab = (nextTab + 1) % n;
            }
            setOpenTab(nextTab);
        } else if (e.key === 'ArrowLeft' && isOpen) {
            let prevTab = (openTab - 1 + n) % n;
            while (!tabAccess[prevTab]) {
                prevTab = (prevTab - 1 + n) % n;
            }
            setOpenTab(prevTab);
        }
        e.stopPropagation();
    };

    return (
        <Dialog
            ref={employeePanelRef}
            id="ansatt-panel-dialog"
            open={isOpen}
            hideBackdrop={!fullView}
            fullWidth={fullView}
            onMouseEnter={(e) => {
                if (!('ontouchstart' in window || navigator.maxTouchPoints > 0)) {
                    e.stopPropagation();
                    setAllowBackdropScroll(false);
                }
            }}
            onMouseLeave={(e) => {
                if (!('ontouchstart' in window || navigator.maxTouchPoints > 0)) {
                    e.stopPropagation();
                    setAllowBackdropScroll(true);
                }
            }}
            transitionDuration={400}
            disableScrollLock={!isMobile}
            disableAutoFocus
            disableEnforceFocus
            disableEscapeKeyDown
            disablePortal
            disableRestoreFocus
            maxWidth="xl"
            fullScreen={isMobile}
            sx={
                isMobile
                    ? {
                          zIndex: zIndexOverride ?? 1000,
                          borderRadius: 0,
                          transition: 'all 0.3s ease-in-out',
                          '& .MuiDialog-paper': {
                              right: shouldTransition ? '-100%' : fullView ? '12%' : 0,
                              transition: 'all 0.15s ease-in-out',
                              overflow: 'hidden',
                              backgroundColor: theme.palette.background.default,
                          },
                      }
                    : {
                          height: '100%',
                          width: fullView ? '80%' : '400px',
                          maxWidth: '100%',
                          zIndex: zIndexOverride ?? 100,
                          borderRadius: 0,
                          left: 'auto',
                          transition: 'all 0.3s ease-in-out',
                          '& .MuiDialog-paper': {
                              width: '100%',
                              maxHeight: `calc(100% - ${topOverride ? topOverride : 0}px)`,
                              height: '100%',
                              top: topOverride ? topOverride / 2 : 0,
                              right: shouldTransition ? '-100%' : fullView ? '12%' : 0,
                              transition: 'all 0.15s ease-in-out',
                              borderRadius: fullView ? '4px' : '4px 0px 0px 4px',
                              overflow: 'hidden',
                              margin: 0,
                              backgroundColor: theme.palette.background.default,
                          },
                          '& .MuiDialog-container': {
                              width: '100%',
                              transition: 'transform 0.15s ease-in-out',
                          },
                      }
            }
        >
            {mePending || employeePending ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%" width={'100%'}>
                    <CircularProgress />
                </Box>
            ) : (
                <Grid2 container flexWrap={'nowrap'} direction="column" spacing={0} height={'100%'}>
                    <Grid2 style={{ height: 'min-content' }} sx={{ zIndex: 2 }}>
                        <TopBar close={close} />
                    </Grid2>

                    <Grid2
                        flexGrow={1}
                        sx={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            paddingX: '10px',
                            marginTop: '-2px',
                            '&::-webkit-scrollbar': {
                                width: '7px',
                                height: '10px',
                            },
                            '&::-webkit-scrollbar-track': {
                                borderRadius: '4px',
                                backgroundColor: 'rgba(0, 0, 0, .1)',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '4px',
                                backgroundColor: 'rgba(144, 144, 144, 1)',
                            },
                            '::-webkit-scrollbar-corner': {
                                backgroundColor: 'transparent',
                            },
                        }}
                        ref={scrollRef}
                    >
                        {me?.Id !== employee?.Id && !employee ? (
                            <p>Ingen bruker funnet med id {employeeId}</p>
                        ) : (
                            {
                                [TabsEnum.Plan]: <PlanTab />,
                                [TabsEnum.Status]: <InfoTab />,
                                [TabsEnum.Ferie]: (
                                    <SignalREventHubContext>
                                        <FerieTab
                                            openSubTab={openSubTab}
                                            setOpenSubTab={(ferieTab: FerieTabEnum) => setOpenSubTab(ferieTab)}
                                        />
                                    </SignalREventHubContext>
                                ),
                                [TabsEnum.Logout]: <LogoutTab />,
                            }[openTab]
                        )}
                    </Grid2>

                    {scrollRef.current && showScrollButton && (
                        <TbButton
                            variant="contained"
                            sx={{
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                position: 'absolute',
                                top: '60px',
                                placeSelf: 'center',
                                width: '40px',
                                height: '40px',
                                display: showScrollButton ? 'block' : 'none',
                                transition: 'all 0.3s ease',
                            }}
                            onClick={() => {
                                scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                            icon={faArrowUp}
                        />
                    )}

                    {isOpen && <EventOnMount />}

                    <Grid2
                        sx={{
                            placeSelf: 'center',
                            width: '100%',
                            placeContent: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <TabSelector tabValue={openTab} setTabValue={setOpenTab} />
                    </Grid2>
                </Grid2>
            )}
        </Dialog>
    );
};
export default EmployeeDrawer;
